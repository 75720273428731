html,
body {
  font-size: 16px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  position: relative;
}

*:focus {
  outline: 0 !important;
}

textarea::placeholder {
  color: #9ca3af;
}

.touch-scroll {
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.wrap-body {
  white-space: pre-wrap;
  /* line-break: anywhere; */
}

.gradient-text {
  background: #2563eb;
  background: linear-gradient(
    110deg,
    #1d4ed8 0%,
    #2563eb 20%,
    #7e22ce 40%,
    #e11d48 75%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.state-icon {
  width: 120px;
  height: 120px;
  fill: #3a3d46;
}

@media (min-width: 1024px) {
  .state-icon {
    width: 108px;
    height: 108px;
  }
}

.state-icon:hover {
  fill: #2563eb;
  transition: fill 0.3s ease;
}

@media (min-width: 1024px) {
  .drawer {
    height: 100% !important;
    width: 480px !important;
  }
}

.react-loading-skeleton {
  background-color: #242832 !important;
  background-image: linear-gradient(
    90deg,
    #242832,
    #292d37,
    #242832
  ) !important;
}
